.customer-doc-summary-card .info {
    display: flex;
    align-items: flex-start;
    font-size: small;
}
.customer-doc-summary-card .info p {
    margin: 0;
}
.customer-doc-summary-card .info svg {
    width: 24px;
    height: auto;
    fill: var(--color-accent);
    margin-right: var(--default-card-spacing);
}
.customer-doc-summary-card .doc-container .headline {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 3px;
}
.customer-doc-summary-card .photo-container .label {
    font-size: var(--font-small);
    opacity: 0.5;
    text-align: left;
    padding-bottom: 3px;
}
.customer-doc-summary-card .photo-container img {
    max-height: 128px;
    width: auto;
}
.customer-doc-summary-card .photo-container img.space-left {
    margin-left: var(--default-card-spacing);
}