.id-card-selector-example img {
    max-height: 128px;
    margin-top: 12px;
}

.id-card-selector-example {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: small;
}

.id-card-selector-example .example-title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #afafaf;
    padding-bottom: 3px;
}
.id-card-selector-example .example-title .num {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    width: 24px;
    height: 24px;
    color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    margin-right: 9px;
}