.agency-pt-card.row {
    display: flex;
    flex-direction: column;
}
.agency-pt-card .spacer {
    flex: 1;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .agency-pt-card.row {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .agency-pt-card .spacer {
        margin-top: var(--default-spacing);
    }
}