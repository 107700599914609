.chat-box-input {
    position: relative;
}
.chat-box-input .chatinputbox {
    resize: none;
    border-width: 1px;
    border-style: solid;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    margin: 6px 0;
    padding-left: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: calc(var(--default-card-spacing) + 40px);
    box-sizing: border-box;
    color: currentColor;
    font-family: "Poppins";
    font-size: var(--font-normal);
}

.chat-box-input .np-icon-button-container {
    position: absolute;
    right: var(--default-spacing);
    bottom: var(--default-spacing);
}