@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-content-primary-grad: linear-gradient(45deg, #005eff, #18e566);
  --color-content-bg: #fff;
}

b {
  font-family: "Poppins-Bold";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-desktop {
  display: none;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
  .no-desktop {
    display: block;
  }

  .no-mobile {
    display: none;
  }
}