.id-card-example img {
    max-height: 128px;
    margin-top: 12px;
}

.id-card-example {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: small;
}