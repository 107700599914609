.negotiation-widget .card-title {
    margin-bottom: 6px;
}

.negotiation-widget .core-info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--default-spacing);
    /* background-color: #e6f9ff; */
    margin-left: calc(-1 * var(--default-spacing));
    margin-right: calc(-1 * var(--default-spacing));
    padding: 6px var(--default-spacing);
}


.negotiation-widget .chatinputbox {
    resize: none;
    border-width: 1px;
    border-style: solid;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    margin: 6px 0;
    padding-left: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: calc(var(--default-card-spacing) + 40px);
    box-sizing: border-box;
    color: currentColor;
    font-family: "Poppins";
    font-size: var(--font-normal);
}

.negotiation-widget .chat-input-container {
    position: relative;
}

.negotiation-widget .chat-input-container .np-icon-button-container {
    position: absolute;
    right: var(--default-card-spacing);
    bottom: var(--default-card-spacing);
}

.negotiation-widget .examples {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #efefef;
    margin-top: var(--default-card-spacing);
}

.negotiation-widget .examples .label {
    font-size: var(--font-small);
    opacity: 0.8;
    margin-top: calc(1/2 * var(--default-card-spacing));
    margin-bottom: calc(2 * var(--default-card-spacing));
    padding-left: var(--default-card-spacing);
}

.negotiation-widget .examples .buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.negotiation-widget .conversation-area {
    opacity: 0;
}
.negotiation-widget .conversation-area.show-true {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
}