/******************************************************
* ROW
******************************************************/
.responsive-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.responsive-row.flex-start {
    align-items: flex-start;
}

.responsive-row.flex-end {
    align-items: flex-end;
}

.responsive-row.justify-center {
    justify-content: center;
}


/******************************************************
* SPACERS
******************************************************/
.hspacer {
    margin: 12px 0;
}

.hspacer.small {
    margin: 6px 0;
}

.hspacer.large {
    margin: 24px 0;
}

.hspacer.x-large {
    margin: 48px 0;
}

.hspacer.xx-large {
    margin: 72px 0;
}

/******************************************************
* SECTIONS
******************************************************/
.landing-section {
    display: flex;
    justify-content: center;
    padding: 24px 0;
}

.landing-section .section-content {
    width: 80vw;
    max-width: 1200px;
}

/******************************************************
* NAVIGATOR
******************************************************/
.navigator {
    display: flex;
}


/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {

    /******************************************************
* ROW
******************************************************/
    .responsive-row {
        flex-direction: column;
    }

}