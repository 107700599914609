.customer-doc-upload-card .instructions {
    border-bottom: 1px solid #efefef;
    padding-bottom: 6px;
}

.customer-doc-upload-card .upload-box {
    margin-top: 12px;
}
.customer-doc-upload-card .upload-box .details {
    font-size: smaller;
    opacity: 0.8;
    padding-bottom: 6px;
}