.negotiation-message .time {
    font-size: var(--font-small);
    padding-left: 20px;
    padding-bottom: 3px;
    opacity: 0.6;
}
.negotiation-message.me-false .time {
    text-align: right;
    padding-right: 20px;
}

.negotiation-message-bubble {
    border-radius: 20px;
    padding: 12px 20px;
    background-color: #d0d0d0;
    opacity: 0.5;
    margin-left: 20%;
}

.negotiation-message.me-true .negotiation-message-bubble {
    opacity: 1;
    color: white;
    margin-right: 20%;
    margin-left: 0;
    background-color: var(--color-primary);
}
.negotiation-message.me-false .negotiation-message-bubble {
    opacity: 1;
    background-color: var(--color-accent);
}


.negotiation-message-bubble p {
    margin: 3px 0;
}