.tx-header-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: calc(-1 * var(--default-spacing));
}

.tx-header-card .tx-header-box {
    display: flex;
    flex: 1 1;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    margin-right: var(--default-spacing);
}

.tx-header-card .image {
    display: flex;
    height: 100px;
    padding-left: 6px;
    padding-right: calc(var(--default-spacing)) !important;
}

.tx-header-card .image img {
    height: 100px;
    width: auto;
}


/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .tx-header-card .image {
        height: 70px;
    }
    .tx-header-card .image img {
        height: 70px;
        width: auto;
    }
}