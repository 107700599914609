/************************************************
 * CUSTOMER TRANSACTION (list item)
 ***********************************************/
.customer-dashboard .customer-transaction {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 6px calc(1 * var(--default-spacing));
    box-sizing: border-box;
    margin-left: calc(-1 * var(--default-spacing));
    margin-right: calc(-1 * var(--default-spacing));
}

.customer-transaction .cell {
    margin: 0 6px;
}



/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
}