.ad-box {
    display: flex;
    background-color: #f4f4f4;
    border-radius: 5px;
    box-shadow: 1px 1px 5px -1px rgba(0,0,0,0.3);
    padding: 24px;
}
.ad-box .image-container {
    margin-right: 24px;
}
.ad-box img {
    width: 128px;
    height: auto;
    border-radius: 5px;
}
.ad-box .row {
    display: flex;
}
.ad-box .ad-values {
    display: flex;
    flex-direction: column;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) { 
    .ad-box {
        background: none;
        box-shadow: none;
        padding: 0;
        align-items: center;
    }

}