
.np-select {
    display: flex;
    flex-direction: column;
    margin: 12px 0;
}
.np-select.short {
    width: 50%;
}

.np-select .np-input-label {
    text-align: left;
    padding-left: 13px;
}

.np-select .border-ok {
    border-color: #e1e1e1;
}

.np-select .text-input-container {
    position: relative;
    flex: 1;
}
.np-select .text-input-container .feedback-icon {
    position: absolute;
    right: 50px;
    top: 14px;
    width: 20px;
    height: 20px;
    fill: currentColor;
}
.np-select .text-input-container .feedback-icon svg {
    height: 20px;
    width: 20px;
}

.np-select .text-input-container.capitalize div {
    text-transform: capitalize;
}
.np-select .row-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
}
.np-select .help-icon {
    margin-left: 12px;
}