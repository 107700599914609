.otp-confirmation-popup {
    max-width: 600px;
    min-width: 30vw;
}

.otp-confirmation-popup .otp-sent-text {
    font-size: x-small;
}
.otp-confirmation-popup .otp-sent-text.large {
    font-size: medium !important;
}

.otp-confirmation-popup .otp-sent-text .link {
    text-decoration: underline;
    color: var(--color-primary);
    cursor: pointer;
}

.otp-confirmation-popup .otp-input-container {
    max-width: 250px;
}

.otp-confirmation-popup .phone-container {
    display: flex;
    align-items: center;
}

.otp-confirmation-popup .phone-container .button-container {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    margin-top: 20px;
    margin-left: 12px;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .otp-confirmation-popup {
        padding: var(--default-spacing);
    }
    .otp-confirmation-popup .phone-container {
        flex-direction: column;
    }
    .otp-confirmation-popup .phone-container .button-container {
        margin: 0;
    }
}
