.landing-create-profile .title {
    font-size: large;
    font-family: 'Poppins-Bold';
}
.landing-create-profile .ad-container {
    margin-left: 48px;
}
.landing-create-profile .form {
    min-width: 30vw;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) { 
    .landing-create-profile .ad-container {
        margin-left: 0;
    }
}