.conversation .conversation-title {
    font-size: var(--font-large);
    margin-bottom: var(--default-spacing);
    display: flex;
    align-items: center;
}
.conversation .conversation-title .logo {
    height: 18px;
}
.conversation .conversation-title .logo svg {
    fill: var(--color-primary);
    height: 18px;
    width: auto;
    margin-right: var(--default-card-spacing);
}

.conversation .buttons-container {
    margin-top: calc(2*var(--default-spacing));
    display: flex;
    flex-direction: column;
}
.conversation .more-details {
    background-color: lightgrey;
    border-radius: 20px;
    padding: 3px 24px;
    font-size: var(--font-small);
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    cursor: pointer;
}
.conversation .more-details:hover {
    background-color: var(--color-accent);
}
.conversation .more-details svg {
    height: 12px;
    width: auto;
}