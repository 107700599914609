
.conversation-chat-box {
    margin-top: var(--default-card-spacing);
    padding-top: 6px;
    border-top: 1px solid #efefef;
}

.conversation-chat-box .answer-title {
    margin-bottom: var(--default-spacing);
}

.conversation-chat-box .box {
    border: 1px solid #efefef;
    border-radius: 20px;
    position: relative;
}
.conversation-chat-box .box .disclaimer {
    padding: 0 var(--default-spacing);
    margin-bottom: var(--default-spacing);
}
.conversation-chat-box .chatinputbox {
    border: none;
    outline: none;
}
.conversation-chat-box .inputs-container {
    display: flex;
    align-items: flex-end;
}
.conversation-chat-box .inputs-container .input-container {
    margin-right: var(--default-card-spacing);
    padding: 0 var(--default-spacing);
}

.conversation-chat-box .box .seller-buttons-container { 
    display: flex;
    justify-content: flex-end;
    padding-right: var(--default-spacing);
    padding-bottom: var(--default-spacing);
}

/*******************+ POPUP ************************/
.conv-chat-box-confirmation-popup {
    display: flex;
    flex-direction: column;
    padding: var(--default-spacing);
    font-size: var(--font-large);
    margin: var(--default-spacing);
}
.conv-chat-box-confirmation-popup .question {
    font-size: var(--font-large);
    margin-bottom: var(--default-spacing);
}
.conv-chat-box-confirmation-popup .values {
    display: flex;
    margin-bottom: var(--default-spacing);
}
.conv-chat-box-confirmation-popup .values .np-labeled-value {
    flex: 1;
}
.conv-chat-box-confirmation-popup .buttons-container {
    display: flex;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .conversation-chat-box .inputs-container {
        flex-direction: row;
    }
}