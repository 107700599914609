.in-app-screen .page.single, .in-app-screen.default .page.single {
    display: flex;
    flex-direction: column;
    width: 100vw;
}
.in-app-screen.style-wide .page.single {
    width: min(95vw, 900px);
}
.in-app-screen.style-narrow .page.single .content {
    max-width: 800px;
    min-width: 40vw;
    align-self: center;
}

.in-app-screen .page.single .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    /* min-width: 600px; */
}
.in-app-screen .page.single .page-title {
    display: flex;
    text-align: left;
    margin-left: var(--default-spacing);
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 800px) {
    .in-app-screen .page.single, .in-app-screen.style-default .page.single {
        width: min(95vw, 800px);
    }
    .in-app-screen.style-wide .page.single {
        width: min(95vw, 800px);
    }
}
@media only screen and (max-width: 400px) {
    .in-app-screen .page.single, .in-app-screen.style-default .page.single {
        width: 95vw;
    }
    .in-app-screen.style-wide .page.single {
        width: 95vw;
    }
}