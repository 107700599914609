.in-app-screen {
    display: flex;
    flex-direction: row;
    padding-top: calc(var(--header-height));
    justify-content: center;
    background-color: var(--color-background);
    min-height: calc(100vh - var(--header-height));
}

.in-app-screen .page {
    display: flex;
    flex-direction: column;
    /* width: clamp(650px, 60vw, 1020px); */
    /* width: 60vw; */
}

.in-app-screen .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 48px;
}


/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) {
    .in-app-screen {
        padding-top: 56px;
    }

    .in-app-screen .page {
        width: 100vw;
    }
}