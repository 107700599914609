

.in-app-screen .header {
    position: fixed;
    background-color: var(--color-header);
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: var(--header-height);
    min-height: var(--header-height);
    border-bottom: 2px solid #efefef;
    padding: 24px 2vw;
    z-index: 2;
    box-sizing: border-box;
}

.in-app-screen .header .pleggit-logo {
    cursor: pointer;
}

.in-app-screen .header .at-logo svg {
    height: var(--header-at-logo-height);
    width: auto;
    margin-right: 24px;
}
.in-app-screen .header .pleggit-logo svg {
    height: var(--header-logo-height);
    width: auto;
    margin-right: 12px;
    fill: white;
}

.in-app-screen .header .pleggit-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.in-app-screen .header .pleggit-title svg {
    height: var(--header-logo-height);
    width: auto;
    fill: white;
}

.in-app-screen .header .pleggit-menu {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
}

.in-app-screen .header .pleggit-menu .menu-item {
    margin: 0 2vw;
    font-size: var(--font-menu-item);
    cursor: pointer;
}

.in-app-screen .header .pleggit-menu .menu-item:hover {
    color: var(--color-accent-mkt)
}

.in-app-screen .header .pleggit-menu-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 24px;
}
.in-app-screen .header .pleggit-menu-icon.only-mobile {
    display: none;
}

.in-app-screen .header .pleggit-menu-icon:hover svg {
    fill: var(--color-accent-mkt)
}

.in-app-screen .header .pleggit-menu-icon svg {
    width: 32px;
    height: auto;
    fill: white;
}

.in-app-screen .header .agency-image img {
    height: var(--header-logo-height);
    width: auto;
}

/**********************************************/
/************+ FOR MOBILE ONLY ****************/
/**********************************************/
@media only screen and (max-width: 500px) {

    .in-app-screen .header .at-logo svg {
        height: calc(2/3 * var(--header-at-logo-height));
        width: auto;
        margin-right: 24px;
    }
    .in-app-screen .header .pleggit-logo svg {
        height: calc(2/3 * var(--header-logo-height));
        margin-right: 6px;
    }
    .in-app-screen .header .pleggit-title svg {
        height: calc(2/3 * var(--header-logo-height));
    }
    
       
    .in-app-screen .header {
        padding: 0px 4vw;
    }
    .in-app-screen .header .pleggit-menu .menu-item {
        display: none;
    }
    .in-app-screen .header .pleggit-menu-icon {
        display: flex;
    }
    .in-app-screen .header .pleggit-menu-icon svg {
        width: 5vw;
    }
}