.welcome-section .title {
    font-size: x-large;
}
.intro-section .text {
    font-size: normal;
}
.welcome-image-container svg {
    width: 30vw;
    max-height: 300px;
    height: auto;
}
.welcome-image-container.small svg {
    width: 30vw;
    max-height: 150px;
    height: auto;
}

.welcome-section video {
    width: 30vw;
    height: auto;
}

/************+ FOR MOBILE ONLY ****************/
@media only screen and (max-width: 400px) { 
    .welcome-section video {
        width: 90vw;
        margin-bottom: 12px;
    }
}